import { toErrorWithMessage } from "@/src/helpers/helper";
import ModalAlert from "@/src/helpers/modalAlert";
import BaseModel from "@/src/models/baseModel";
import { ObjectDynamicProp } from "@/src/utils/globalTypes";
import { Popover } from "@headlessui/react";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import Images from "../image/Images";
import Input from "../input/Input";

import { PopCustom } from "../popover/Pop";

interface Props {
  value: {
    id: number | string | null;
    name: string;
  };
  options?: { id: any; name: string }[];
  onChangeValue?: (e: { id: any; name: string }) => void;
  className?: string;
  panelClassName?: string;
  buttonElement: ReactNode;
  textClassName?: string;
  disabled?: boolean;
  api?: string;
  baseUrl?: string;
  params?: ObjectDynamicProp;
  placeholder?: string;
  noSearch?: boolean;
  containerClassName?: string;
  additionalValue?: {
    type: "country";
    value: string[];
  };
}

type ListProps = {
  current_page: number;
  from: number;
  last_page: number;
  to: number;
  total: number;
  items: Array<any>;
};

const SelectSearch: FC<Props> = ({
  value,
  options,
  onChangeValue,
  className = "",
  panelClassName = "",
  buttonElement,
  textClassName = "text-regular-tight",
  disabled,
  api,
  baseUrl,
  params,
  placeholder = "Input text",
  noSearch,
  containerClassName,
  additionalValue,
}) => {
  const client = new BaseModel();

  const searchRef = useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = useState("");
  const [listData, setListData] = useState<{ id: number; name: string }[]>([]);
  const [close, setClose] = useState(true);
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "flip", //flips popper with allowed placements
        options: {
          fallbackPlacements: ["left", "right"],
          allowedAutoPlacements: ["top", "bottom"],
          rootBoundary: "viewport",
        },
      },
    ],
  });

  const [item, setItem] = useState<{
    id: number | string | null;
    name: string;
  }>({
    id: value.id || null,
    name: value.name || "",
  });

  const [paging, setPaging] = useState({
    total: 0,
    current_page: 0,
    last_page: 0,
    from: 1,
    to: 10,
  });

  const getData = async () => {
    try {
      if (api && !disabled) {
        if (baseUrl) {
          client.switchBase(baseUrl);
        }

        const { data } = await client.request({
          method: "get",
          url: api,
          params: {
            keyword,
            ...params,
          },
        });

        if (data.items) {
          const { items: res, ...page } = data as ListProps;
          setListData(res);
          setPaging(page);
        } else {
          setListData(data);
        }
      } else {
        if (options) {
          const cek = keyword
            ? options.filter((e: any) =>
                e.name.toLowerCase().includes(keyword.toLowerCase())
              )
            : options;
          setListData(cek);
        }
      }
    } catch (error) {
      setListData([]);
      const msg = toErrorWithMessage(error).message;
      ModalAlert({
        title: "Oops!!!",
        message: msg || "Error!!!",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (value.id && value.name) {
      setItem(value);
    }
  }, [value]);

  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, options, disabled]);

  useEffect(() => {
    if (popperElement) {
      setClose(false);
    } else {
      setClose(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  return (
    <PopCustom
      containerClassName={`w-full ${containerClassName}`}
      btnRef={setReferenceElement}
      btnClassName=""
      button={<>{buttonElement}</>}
      disabled={disabled}
    >
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className={`bg-white rounded-xl shadow-lv4 w-auto p-4.5 focus-visible:!outline-none  ${panelClassName}`}
      >
        {({ close }) => (
          <div className="flex flex-col gap-4.5">
            {!noSearch && (
              <Input
                icon="SearchIcon"
                iconPosition="right"
                innerRef={searchRef}
                name="search"
                value={keyword}
                onInput={(e) => {
                  setKeyword(e.target.value);
                }}
                label="Search...."
                autoFocus
                autoComplete="off"
                placeholder={placeholder}
              />
            )}
            <div className="max-h-[15rem] overflow-y-auto sidebar">
              <div className="grid gap-y-3">
                {listData.map((e, key: number) => (
                  <div
                    key={key}
                    className={`${textClassName} text-neutral-900 font-medium truncate ${
                      item.id === e.id
                        ? "text-primary-500 font-medium"
                        : "text-neutral-900"
                    } cursor-pointer capitalize text-left`}
                    onClick={() => {
                      setItem(e);
                      onChangeValue?.(e);
                      close();
                    }}
                  >
                    {additionalValue !== undefined ? (
                      <>
                        {additionalValue.type === "country" && (
                          <div className="flex items-center gap-1">
                            <div className="w-8">
                              <Images
                                src={(e as any)[additionalValue.value[0]]}
                                className="w-full"
                                alt="phone-code"
                              />
                            </div>
                            <div className="text-small-normal text-neutral-900">
                              {(e as any)[additionalValue.value[1]]}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      e.name
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Popover.Panel>
    </PopCustom>
  );
};

export default SelectSearch;
